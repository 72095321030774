import styled from "styled-components";
import { useHistory, Link} from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faRotateLeft
  } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

  const Mobile = 699;

const Wrapper = styled.div`
    background-color: white;
    position: fixed;
    width: 100%;
    height: 100%;
`

const Box = styled.div`
  padding-top: 2vh;
  position: absolute;
`

const Top = styled.div`
    width: 80vw;
    top: 9vh;
    height: 9vh;
    line-height: 9vh;
    padding: 0 1vw;
    border-bottom: 3px solid #4767b0;
    float: left;
    
    span{
        font-size: 2vw;
        cursor: pointer;        
    }
    @media only screen and (max-width: ${Mobile}px){
        height: 10vh;
        line-height: 10vh;
        span{
            font-size: 20px;
        }
    }
`

const Middle = styled.div`
    width: 96vw;
    padding: 1.5vh 1vw;
    text-align: center;
    float: left;
    background-color: white;
    img{
        max-width: 80vw;
        max-height: 80vh;
        border-radius: 20px;
        margin: 3vh 0 ;
    }
    @media only screen and (max-width: ${Mobile}px){
        img{
            max-width: 94vw;
            margin-top: 4vh;
            margin-bottom: 2vh;
        }
    }
`

const Bottom = styled.div`
    width: 48vw;
    float: left;
    padding: 0 1vw;
    padding-bottom: 3vw;
    a{
        text-decoration: underline;
    }
    @media only screen and (max-width: ${Mobile}px){
        width: 96vw;
        margin-bottom: 3vh;
    }
`
const Smallbox2 = styled.div`
  height: 9vh;
  width: 96vw;
  line-height: 9vh;
  text-align: center;
  border-top: 3px solid #4767b0;
  float: left;
  @media only screen and (max-width: ${Mobile}px){
    height: 12vh;
    line-height: 12vh;
}
`

const Circle = styled.span`
  background-color: #f5b41a;
  color: #4767b0;
  border-radius: 100%;
  display: inline-block;
  font-size: 1.5vw;
  padding: 0.5vh 1vw;
  cursor: pointer;
  font-weight: 500;
  @media only screen and (max-width: ${Mobile}px){
    font-size: 20px;
    padding: 1vh 3vw;
  }
`

const Topbox1 = styled.div`
    height: 9vh;
    border-bottom: 3px solid #4767b0;
    display: flex;
    align-items: center;
    padding: 0 1vw 1vh 1vw;
    width: 48vw;
    float: left;
    @media only screen and (max-width: ${Mobile}px){
        width: 30vw;
        height: 12vh;
        &:nth-child(2){
            width: 66vw;
        }
    }
`

interface IProject{
    title: string,
    image: string,
    link: string,
    type: string,
    story: string,
    what: string,
    when: string,
    detail: string,
    github: string,
}

interface IContent{
    content: IProject
}

function More({content}: IContent) {
    const [road, setRoad] = useState(true);
    const [name, setName] = useState(true);
    const [arrow, setArrow] = useState(false);

    const history = useHistory()
    const freshPage = () => {
        history.go(0)
    }

    return(
        <>
            <Wrapper/>
            <Box>
                <Topbox1>
                <span
                    onMouseOver={()=> setRoad(false)}
                    onMouseOut={()=> setRoad(true)}>
                    {road? 
                        <Circle>
                        Work
                        </Circle>
                        :<Circle style={{backgroundColor: "#e8a2cf"}}>
                        <Link to="/about">Info</Link>
                        </Circle>
                    }
                </span>
                </Topbox1>
                
                <Topbox1 style={{justifyContent:"right"}}>
                    <span
                        onMouseOver={()=> setName(false)}
                        onMouseOut={()=> setName(true)}>
                    {name?
                        <span>eunalee</span>
                        :<span>graphic designer & web developer</span>
                    }
                    </span>
                </Topbox1>
            <Top>
                <h1>{content.title}</h1>
            </Top>
            <Top
                onClick={freshPage}
                onMouseOut={()=> setArrow(false)}
                onMouseOver={()=> setArrow(true)}
                style={{textAlign: "right", width: "16vw", cursor: "pointer"}}>
                <span> 
                    <a href="/">
                        {arrow?
                            <FontAwesomeIcon icon={faRotateLeft} spin spinReverse/> 
                            :<FontAwesomeIcon icon={faRotateLeft} />
                        }
                    </a>
                </span>
            </Top>

            <Middle>
                <img src={ require(`./img/${content.image}`)}/>
                {/* {content.story} */}
            </Middle>

            <Bottom>
                · {content.what} ({content.when})
                    <br/>
                · {content.detail} 
                    <br/>
                
                {/* · <a href={content.link} target="_blank">link</a> */}
                  {content.link !== "" && 
                    <> · <a href={content.link} target="_blank">link</a></>
                  }  
                  {content.github !== "" && 
                    <> / <a href={content.github} target="_blank">github</a></>
                  }  
                {/* {content.detail.includes("github") ?
                    <>· <a href={content.detail} target="_blank">github</a></>:
                    <>· {content.detail}</>
                } */}
            </Bottom>

            <Bottom>
                {content.story} 
            </Bottom>
            <Smallbox2>© 2023 Euna Lee</Smallbox2>
            </Box>
        </>
    )
}

export default More;