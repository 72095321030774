import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";

const Mobile = 699;

const Wrapper = styled.div`
    padding: 2vh 2vw;
`

const Table = styled.div`
  float: left;
  width: 96vw;
  height: 100vh;
`
const Smallbox2 = styled.div`
  height: 9vh;
  width: 96vw;
  line-height: 9vh;
  text-align: center;
  border-top: 3px solid #4767b0;
  float: left;
  @media only screen and (max-width: ${Mobile}px){
    height: 12vh;
    line-height: 12vh;
}
`

const Topbox1 = styled.div`
    height: 9vh;
    border-bottom: 3px solid #4767b0;
    display: flex;
    align-items: center;
    padding: 0 1vw 1vh 1vw;
    width: 48vw;
    float: left;
    @media only screen and (max-width: ${Mobile}px){
        width: 30vw;
        height: 12vh;
        &:nth-child(2){
            width: 66vw;
        }
    }
`
const S1 = styled.div`
  float: left;
  width: 48vw;
  padding: 2vh 1vw;
    a{
        color: #4767b0;
        &:hover{
            // text-decoration: underline;
            // text-decoration-thickness: 3px;
            padding: 0 1vw;
            color: #e8a2cf;
            font-style: italic;
        }
    }
    @media only screen and (max-width: ${Mobile}px){
        width: 96vw;
        padding: 2vh 1vw;
        border-bottom: 3px solid #4767b0;
        h1{
            line-height: 42px;
        }
    }
`

const S2 = styled.div`
  float: left;
  width: 48vw;
  min-height: 81vh;
  padding: 3vh 1vw 5vh 1vw;
  h2{
      cursor: pointer;
      &:hover{
          text-decoration: underline;
          color: #e8a2cf;
      }
  }
  @media only screen and (max-width: ${Mobile}px){
    width: 96vw;
    padding: 2.5vh 1vw;
    }
`


const Circle = styled.span`
  background-color: #e8a2cf;
  color: #4767b0;
  border-radius: 100%;
  display: inline-block;
  font-size: 1.5vw;
  padding: 0.5vh 1vw;
  cursor: pointer;
  font-weight: 500;
  @media only screen and (max-width: ${Mobile}px){
    font-size: 20px;
    padding: 1vh 3vw;
  }
`


function About () {
    const [road, setRoad] = useState(true);
    const [name, setName] = useState(true);
    const [exp, setExp] = useState(false);
    const [edu, setEdu] = useState(false);
    const [skill, setSkill] = useState(false);
    
    return(
        <Wrapper>
            <Table>
                <Topbox1>
                <span
                    onMouseOver={()=> setRoad(false)}
                    onMouseOut={()=> setRoad(true)}>
                    {road? 
                        <Circle>
                        Info
                        </Circle>
                        :<Circle style={{backgroundColor: "#f5b41a"}}>
                        <Link to="/">Work</Link>
                        </Circle>
                    }
                </span>
                </Topbox1>
                
                <Topbox1 style={{justifyContent:"right"}}>
                    <span
                        onMouseOver={()=> setName(false)}
                        onMouseOut={()=> setName(true)}>
                    {name?
                        <span>eunalee</span>
                        :<span>graphic designer & web developer</span>
                    }
                    </span>
                </Topbox1>
                {/* <Smallbox> */}
                    <S1 style={{lineHeight:"4.2vw"}}>
                        <h1><a href="mailto:contact@euna-lee.com">contact@euna-lee.com</a></h1>
                        <h1><a href="https://www.instagram.com/eeuunnaaa/" target="_blank">Instagram</a></h1>
                        <h1><a href="https://github.com/eunaleeeunalee" target="_blank">Github</a></h1>
                    </S1>
                    <S2>
                        I am <em>Euna Lee</em>, a graphic designer and web developer based in Malmö, Sweden. I work in both printed and web-based publishing, and enjoy exploring the boundaries between them.
                        <br/>
                        In 2020, I founded an <em>Mayoyo Publishing</em>, an independant art publishing house, and since 2022, I've been a member of <em>Not Just a Collective</em>.
                        <br/><br/>
                        Feel free to contact me for collaboration or simply to say hi :)
                        <br/><br/>
                        <>
                            <h2 onClick={()=> setExp(!exp)}>⤳ Experience</h2>
                            {exp && 
                            <>
                                · Organised <em>Not Just a Fair</em>(2023), Arnhem(NL)
                                <br/>
                                · Developed & designed the web site for <em>Not Just a Collective</em>(2023), Arnhem(NL)
                                <br/>
                                · Conducted workshop <em>Origami Poetry</em> in Zinecamp(2022), Rotterdam(NL)
                                <br/>
                                · Presented <em>Strolling Cat</em> at How far have we come(2021), Berlin(DE)
                                <br/>
                                · Workshop <em>Strolling You</em> in International Walking Arts Encounters/Conference(2021), Prespa(GR)
                                <br/>
                                · Designed the editorial layout for <em>Tooning</em>(2021-2023), Seoul(KO)
                                <br/>
                                · Founded <em>Mayoyo Publishing</em>(2020), Seoul(KO)
                                <br/>
                                · Internship in <em>BasBoek</em>(2019), Apeldoorn(NL)
                                <br/>
                                · Internship in <em>BoaBooks</em>(2018), Geneva(CH)
                                <br/>
                            </>}
                        </>
                        <br/>
                        <>
                            <h2 onClick={()=> setEdu(!edu)}>⤳ Education</h2>
                            {edu &&
                            <>
                                · Master's degree in <em>XPUB</em>(2022) from Piet Zwart Institute, Rotterdam(NL)
                                <br/>
                                · Bachelor's degree in <em>Graphic Design</em>(2019) from École Nationale Supérieure des Beaux-Arts de Lyon, Lyon(FR)
                                <br/>
                            </>}
                        </>
                        <br/>
                        <>
                            <h2 onClick={()=> setSkill(!skill)}>⤳ Skill</h2>
                            {skill &&
                            <>
                                · Web development: HTML, CSS, VanillaJS, ReactJS, Typescript, Python, Lua, Firebase
                                <br/>
                                · Graphic design: Adobe InDesign, Photoshop, Illustrator, Figma, Glyphs
                                <br/>
                                · Language: Korean(native), English(fluent), French(fluent), Chinese(basic)
                            </>
                            }
                        </>                              
                    </S2>
            <Smallbox2>© 2023 Euna Lee</Smallbox2>
            </Table>
            
            
        
            
        </Wrapper>
    )
}

export default About;