import { Link } from "react-router-dom";
import styled from "styled-components";
import project from "./Project.json";
import { useState } from "react";
import More from "./Border";


const Mobile = 699;

const Wrapper = styled.div`
  padding: 2vh 2vw;
`

const Table = styled.div`
  float: left;
  width: 96vw;
  height: 100vh;
`

const S1 = styled.div`
  float: left;
  width: 48vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (max-width: ${Mobile}px){
    width: 60vw;
  }
`

const S2 = styled.div`
  float: left;
  width: 24vw;
  @media only screen and (max-width: ${Mobile}px){
    width: 34vw;
    text-align: right;
  }
`

const S3 = styled.div`
  float: left;
  width: 22vw;
  text-align: right;
  @media only screen and (max-width: ${Mobile}px){
    display: none;
  }
`


const ImgBox = styled.span`
  border: 3px solid #4767b0;
  background-color:white;
  position: absolute;
  margin-left: 1vw;
  z-index: 1;
  margin-top: 3vh;
  img{
    height: 30vh;
    vertical-align:middle;
  }
  @media only screen and (max-width: ${Mobile}px){
    display: none;
  }
`


const ImgBox2 = styled.span`
border: 3px solid #4767b0;
background-color:white;
position: absolute;
margin-left: 1vw;
z-index: 1;
margin-top: 20vh;
img{
  height: 30vh;
  vertical-align:middle;
}
  @media only screen and (max-width: ${Mobile}px){
    display: none;
  }
`

const Topbox1 = styled.div`
  height: 9vh;
  border-bottom: 3px solid #4767b0;
  display: flex;
  align-items: center;
  padding: 0 1vw 1vh 1vw;
  width: 48vw;
  float: left;
  
  a{
    color: #4767b0;
    &:hover{
      text-decoration: underline;
      color: #f5b41a;
    }
  }
  @media only screen and (max-width: ${Mobile}px){
    width: 30vw;
    height: 12vh;
    &:nth-child(2){
        width: 66vw;
    }
}
`

const Menu = styled.div`
  span{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
      color: #f5b41a;
    }
  }
  @media only screen and (max-width: ${Mobile}px){
      display: none;
  }
`

const Smallbox = styled.div`
  height: 9vh;
  border-bottom: 3px solid #4767b0;
  line-height: 9vh;
  padding: 0 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &:hover{
    padding: 0 0 0 2vw;
    font-style: italic;
    color: #f5b41a;
    // color: white;
    // background-color: #f5b41a;
  }
  @media only screen and (max-width: ${Mobile}px){
    height: 10vh;
    line-height: 10vh;
  }
`

const Smallbox2 = styled.div`
  height: 9vh;
  width: 96vw;
  line-height: 9vh;
  text-align: center;
  // padding: 0 1vw;
  @media only screen and (max-width: ${Mobile}px){
    height: 12vh;
    line-height: 12vh;
}
`
const Bottombox = styled.div`
  background-color: white;
  // color: white;
  position: absolute;
  top: 0;
  // height: 100vh;
  z-index: 10;
`

const Circle = styled.span`
  background-color: #f5b41a;
  border-radius: 100%;
  display: inline-block;
  font-size: 1.5vw;
  padding: 0.5vh 1vw;
  cursor: pointer;
  font-weight: 500;
  a{
    text-decoration: none;
    &:hover{
      text-decoration: none;
      color: #4767b0;
    }
  }
  @media only screen and (max-width: ${Mobile}px){
    font-size: 20px;
    padding: 1vh 3vw;
  }
`

function Home(){
  const parDefault = {
    title: "",
    image: "",
    link: "",
    type: "",
    story: "",
    what: "",
    when: "",
    detail: "",
    github: "",
  }

  interface IProject{
    title: string,
    image: string,
    link: string,
    type: string,
    story: string,
    what: string,
    when: string,
    detail: string,
    github: string,
}

interface IContent{
    content: IProject
}


  const [image, setImage] = useState("");
  const [detail, setDetail] = useState(false);
  const [more, setMore] = useState(parDefault);
  const [road, setRoad] = useState(true);
  const [what, setWhat] = useState("all");
  const [name, setName] = useState(true);
  const [xy, setXY] = useState({x:0, y:0})

  //마우스 좌표알아내기
  const handleMouseMove=(e:MouseEvent)=>{
    setXY({x:e.clientX, y:e.clientY});
  }

  const handleClickScroll = (content:IContent) => {
    setMore(content as any);
    setDetail(!detail);
  };

    return(
    <Wrapper>
      <Table>

        <Topbox1>
          <span
          onMouseOver={()=> setRoad(false)}
          onMouseOut={()=> setRoad(true)}>
          {road? 
            <Circle>
              Work
            </Circle>
            :<Circle style={{backgroundColor: "#e8a2cf"}}>
              <Link to="/about">Info</Link>
            </Circle>
          }
          </span>
            <Menu
              style={{fontSize: "1.5vw", marginLeft: "1.5vw"}}>
              {what === "all" ? 
                <span style={{textDecoration:"underline"}}onClick={()=> setWhat("all")}>all</span>
                :<span onClick={()=> setWhat("all")}>all</span>}
              
              &ensp;/&ensp;

              {what === "web" ? 
                <span style={{textDecoration:"underline"}}onClick={()=> setWhat("web")}>web</span>
                :<span onClick={()=> setWhat("web")}><Link to="/web">web</Link></span>}

              &ensp;/&ensp;

              {what === "book" ? 
                <span style={{textDecoration:"underline"}}onClick={()=> setWhat("book")}>book</span>
                :<span onClick={()=> setWhat("book")}><Link to="/publication">publication</Link></span>}
            </Menu>
        </Topbox1>
        <Topbox1 style={{justifyContent:"right"}}>
          <span
              onMouseOver={()=> setName(false)}
              onMouseOut={()=> setName(true)}>
            {name?
                <span>eunalee</span>
                :<span>graphic designer & web developer</span>
            }
          </span>
        </Topbox1>


        
        {project.project.map((pro, index)=> 
          <>
            {what === "all" &&
              <Link to={pro.title}>
                <Smallbox key={pro.title} 
                  onMouseOver={()=> setImage(pro.title)}
                  onMouseOut={()=> setImage("")}
                  onClick={()=> handleClickScroll(pro as any)}
                  onMouseMove = {(e)=>handleMouseMove(e as any)}
                  >
                  <S1>
                    <h1>
                      {pro.title}
                      {image === pro.title &&
                      <>
                        {pro.title ==="Mayoyo Publishing" || pro.title ==="Werner Herzog movie posters"?

                          <ImgBox2 style={{left:xy.x, top:xy.y }}>
                            <img key={pro.title} src={ require(`./img/${pro.image}`)}/>
                          </ImgBox2>:
                          <ImgBox style={{left:xy.x, top:xy.y }}>
                            <img key={pro.title} src={ require(`./img/${pro.image}`)}/>
                          </ImgBox>
                        }
                      </>
                      }
                    </h1>
                  </S1>
                  <S2>
                    {pro.what}
                  </S2>
                  <S3>
                    {pro.when}
                  </S3>
                  
                </Smallbox>
              </Link>}

            {what === "book" &&
              <>
              {pro.type === "book" &&
                <Link to={pro.title}>
                <Smallbox key={pro.title} 
                  onMouseOver={()=> setImage(pro.title)}
                  onMouseOut={()=> setImage("")}
                  onClick={()=> handleClickScroll(pro as any)}
                  onMouseMove = {(e)=>handleMouseMove(e as any)}
                  >
                  <S1>
                    <h1>
                      {pro.title}
                      {image === pro.title && 
                      <ImgBox style={{left:xy.x, top:xy.y }}>
                        <img key={pro.title} src={ require(`./img/${pro.image}`)}/>
                      </ImgBox>
                    }
                    </h1>
                  </S1>
                  <S2>
                    {pro.what}
                  </S2>
                  <S3>
                    {pro.when}
                  </S3>
                  
                </Smallbox>
              </Link>
                }
              </>}

              {what === "web" &&
                <>
                {pro.type === "web" &&
                  <Link to={pro.title}>
                  <Smallbox key={pro.title} 
                    onMouseOver={()=> setImage(pro.title)}
                    onMouseOut={()=> setImage("")}
                    onClick={()=> handleClickScroll(pro as any)}
                    onMouseMove = {(e)=>handleMouseMove(e as any)}
                    >
                    <S1>
                      <h1>
                        {pro.title}
                        {image === pro.title && 
                        <ImgBox style={{left:xy.x, top:xy.y }}>
                          <img key={pro.title} src={ require(`./img/${pro.image}`)}/>
                        </ImgBox>
                      }
                      </h1>
                    </S1>
                    <S2>
                      {pro.what}
                    </S2>
                    <S3>
                      {pro.when}
                    </S3>
                    
                  </Smallbox>
                  </Link>
                }
                </>  
              }
          </>
        )}
        <Smallbox2>© 2023 Euna Lee</Smallbox2>
      </Table>

      {detail &&  
        <Bottombox id="section-1">
          <More content={more}/>
        </Bottombox>}
    </Wrapper>
    )
}


export default Home;
