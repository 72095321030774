import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Home from "./Routes/Home";
import About from "./Routes/About";

// import About from "./Routes/About";

const Mobile = 768;

const Wrapper =styled.div`
  @media only screen and (max-width: ${Mobile}px){

  }
`;


function App() {
  const client = new QueryClient();
  return (
    <QueryClientProvider client={client}>
    <Router>
      <Helmet>
        <title>Euna Lee</title>
      </Helmet>
      <Switch>

        <Wrapper>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        </Wrapper>
      </Switch>

      

      
    </Router>
    </QueryClientProvider>
  );
}

export default App;